import { createSlice } from '@reduxjs/toolkit';

// Get the initial speed unit from localStorage, or default to 'kph'
let initialSpeedUnit = 'kph'; // default unit

if (typeof window !== 'undefined') {
  // Ensure we're in a browser environment
  const savedSpeedUnit = localStorage.getItem('speedUnit');
  if (savedSpeedUnit) {
    initialSpeedUnit = savedSpeedUnit.toLowerCase(); // Ensure lowercase
  }
}

const initialState = {
  speedUnit: initialSpeedUnit,
  // Other user settings can be added here
};

const trackerSlice = createSlice({
  name: 'trackerSettings',
  initialState,
  reducers: {
    setSpeedUnit(state, action) {
      state.speedUnit = action.payload;
      if (typeof window !== 'undefined') {
        // Save the new speed unit to localStorage
        localStorage.setItem('speedUnit', action.payload);
      }
    },
    // Add other reducers for different settings if needed
  },
});

export const { setSpeedUnit } = trackerSlice.actions;
export default trackerSlice.reducer;
