// This module defines the configuration for vMonitor and provides a function to retrieve the environment-specific configuration settings. The configuration settings are primarily URLs for various APIs and services used throughout. It also includes a utility function to get the version of the system from environment variables or a default value.

//Only call this function server-side, for client-side use the useConfig hook
export default function getConfig(): Config {
  const host = process.env.HOST;
  const plcAddress =
    host === "server.dev.rts-cloud.com " && process.env?.DEV_PLC_ADDRESS
      ? process.env.DEV_PLC_ADDRESS
      : `https://${host}/plc-api`;

  return {
    url: {
      API: `https://${host}/vmapi`,
      PLC: plcAddress,
      ALARMS: `/alarms`,
      WEBSOCKET: process.env?.WEBSOCKET_URL ?? `https://${host}`,
      TRACK_STATUS: "/trackStatus",
      REPORTS: "/reports",
      BOOKINGS: process.env.BOOKINGS_URL ?? "/checkins",
      BOOKING_API: `https://${host}/booking-api`,
      DASHBOARD: process.env?.GRAFANA_DASHBOARD ?? `/grafana/`,
      CONFIG: `https://${host}/config`,
      BOOKING_ID: `/booking`,
      TRACKERS_API: `https://${host}/tracker-api`,
      INFLUXDB: process.env?.INFLUXDB_URL ?? `https://${host}/influxdb`,
    },
    host: process.env.HOST ?? "",
    influxdb_token: process.env.INFLUXDB_TOKEN ?? "",
    map: process.env.MAP ?? "volvo",
    isReplay: !!process.env.IS_REPLAY,
    isQA: process.env.IS_QA === "true",
    version: process.env.SYSTEM_VERSION ?? "x.x.x",
    node: process.env.THIS_NODE ?? "",
    plcDisabled: process.env?.PLC_DISABLED ?? "false",
    sitePrefix: process.env?.NEXT_PUBLIC_SITE_PREFIX ?? "SITE",
    swBounds: process.env.NEXT_PUBLIC_LNGLATBOUNDS_SOUTHWEST ?? "",
    neBounds: process.env.NEXT_PUBLIC_LNGLATBOUNDS_NORTHEAST ?? "",
    trackChecks: process.env.NEXT_PUBLIC_TRACK_CHECKS ?? "",
    defaultTrackView: process.env.NEXT_PUBLIC_DEFAULT_TRACK_VIEW ?? "",
    trackStatusSpecial: process.env.NEXT_PUBLIC_TRACK_STATUS_SPECIAL ?? "false",
    specialTracks: process.env.NEXT_PUBLIC_SPECIAL_TRACKS?.split(",") ?? [],
    lights: process.env.NEXT_PUBLIC_LIGHTS ?? "false",
    useTime: process.env.NEXT_PUBLIC_CHECKINS_USE_TIME ?? "true",
  };
}

export interface UserProfile {
  username: string;
  password: string;
  fullname: string;
}
export interface Config {
  url: URL;
  host: string;
  influxdb_token: string;
  map: string;
  isReplay: boolean;
  isQA: boolean;
  version: string;
  node: string;
  plcDisabled: string;
  sitePrefix: string;
  swBounds: string;
  neBounds: string;
  trackChecks: string;
  defaultTrackView: string;
  trackStatusSpecial: string;
  specialTracks: string[];
  lights: string;
    useTime: string;
}

export interface URL {
  API: string;
  PLC: string;
  ALARMS: string;
  WEBSOCKET: string;
  TRACK_STATUS: string;
  REPORTS: string;
  BOOKINGS: string;
  BOOKING_API: string;
  DASHBOARD: string;
  CONFIG: string;
  BOOKING_ID: string;
  TRACKERS_API: string;
  INFLUXDB: string;
}

export interface TrackView {
  longitude: number;
  latitude: number;
  zoom: number;
  maxPitch: number;
  bearing: number;
}
