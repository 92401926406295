import { configureStore } from "@reduxjs/toolkit";
import trackerReducer from "./components/Map/Marker/trackerSlice";
import headerReducer from "./components/Header/HeaderSlice.js";
import trackerWindowsSlice from "./trackerWindowsSlice";
import permissionsSlice from "./permissionsSlice";
import pageModalOpenSlice from "./pageModalSlice";
import statusSlice from "./statusSlice";
import alertSlice from "./components/CheckinsPage/alertSlice";
import dirmaxSlice from "./redux/slices/dirmax";
import categoriesSlice from "./redux/slices/categories";
import barriersSlice from "./redux/slices/barriers";
import replaySlice from "./redux/slices/replay";
import plcBarriersEventSlice from "./redux/slices/plcBarriersEvent";
import plcStatusSlice from "./redux/slices/plcStatus";
import lightsDataSlice from "./redux/slices/lights";
import hoveredTrackerSlice from "./redux/slices/hoveredTracker";
import trackerSlice from "./redux/slices/trackerSlice";

const store = configureStore({
  reducer: {
    trackers: trackerReducer,
    currentTrack: headerReducer,
    trackerWindows: trackerWindowsSlice,
    permissions: permissionsSlice,
    pageModalOpen: pageModalOpenSlice,
    status: statusSlice,
    alert: alertSlice,
    dirmax: dirmaxSlice,
    categories: categoriesSlice,
    barriers: barriersSlice,
    replay: replaySlice,
    plcBarriersEvent: plcBarriersEventSlice,
    plcStatus: plcStatusSlice,
    lightsData: lightsDataSlice,
    hoveredTracker: hoveredTrackerSlice,
    trackerSettings: trackerSlice,
  },
});
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
